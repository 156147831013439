import { DefaultService } from './default.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaisseService {

  constructor(
    private defaultSvc: DefaultService,
    private http: HttpClient
  ) { }

  getSoldeByDevise(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'caisse', httpOptions);
  }

  getEntrees(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'caisse-entree', httpOptions);
  }

  getSorties(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'caisse-sortie', httpOptions);
  }

  paginate(url: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  saveSortie(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'caisse', data, httpOptions);
  }

  recherche(debit: any, fin: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'caisse-search/' + debit + '/' + fin , httpOptions);
  }
}
