import { DefaultService } from './default.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VenteService {

  constructor(
    private defaultSvc: DefaultService,
    private http: HttpClient
  ) { }

  saveCommande(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'commande', data, httpOptions);
  }

  saveFactureProfoma(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'facture-profoma', data, httpOptions);
  }

  getCommandes(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'commande', httpOptions);
  }

  getCommandeBySlug(slug: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'commande-slug/' + slug, httpOptions);
  }


  searchCommande(employeId: number, paiementId: number, debit: any, fin: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb +
      'commande-search/' + employeId + '/' + paiementId + '/' + debit + '/' + fin, httpOptions);
  }

  paginate(url: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(url, httpOptions);
  }

  payCommande(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'paye-commande', data, httpOptions);
  }

  getCommandesExcel(employeId: number, debut: any, fin: any) {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'commandes_employe_date/' + employeId + '/' + debut + '/' + fin, httpOptions);
  }

  returnProducts(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'return_prdocuts', data, httpOptions);
  }

  getVenteJours(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'vente_du_jour', httpOptions);
  }

  searchCommandeClient(debit: any, fin: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb +
      'commande-search-client/' + debit + '/' + fin, httpOptions);
  }

  getCommandeClients(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'commande_clients', httpOptions);
  }

  valideCommandeClient(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'valide_commande_client', data, httpOptions);
  }

  annulerCommandeClient(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'annuler_commande_client', data, httpOptions);
  }

  chercheCommandeWithCodeIMEI(code: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'commande_code/' + code, httpOptions);
  }

}
