import { DefaultService } from './default.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModePaiementService {

  constructor(
    private defaultSvc: DefaultService,
    private http: HttpClient
  ) { }

  save(modepaiement): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'mode_paiements', modepaiement, httpOptions);
  }

  getAll() {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'mode_paiements', httpOptions);
  }

  activatePaiement(id, status){
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'activer_paiement/' + id + '/'+ status, httpOptions);
  }

  updateModePaiement(employe) {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'update_mode_paiement', employe, httpOptions);
  }

  getModePaiementById(id:string){
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'get_modepaiement_by_id/' + id, httpOptions);
  }

  paiementActif(): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'mode-paiement-actif', httpOptions);
  }

}
