import { DefaultService } from './default.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ProduitService {

  constructor(
    private http: HttpClient,
    private defaultSvc: DefaultService
  ) { }

  saveProduct(produit) {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'produit', produit, httpOptions);
  }

  getProducts() {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'produit', httpOptions);
  }

  getProductActifs() {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'produit-actifs', httpOptions);
  }

  getProductByslug(slug: string) {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'product-slug/' + slug, httpOptions);
  }

  getProductOnlyByslug(slug: string): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'product_only_by_slug/' + slug, httpOptions);
  }

  getProdutsForSales() {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'products_sale', httpOptions);
  }

  setStatutProduct(slug: string, statut: string): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'product_salable/' + slug + '/' + statut, httpOptions);
  }

  addQuantity(sProduct: string, data: any): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'add_quantity/' + sProduct, data, httpOptions);
  }

  addImage(data: any): any {
    const httImagepOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'add-image-product', data, httImagepOptions);
  }

  updateProduct(produit){
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'update_produit', produit, httpOptions);
  }

  removeImage(data: any): any {
    const httImagepOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'remove-image-product', data, httImagepOptions);
  }

  getProductRupture(): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'product_ruptures', httpOptions);
  }

  changeProductAccueil(slug: string, etat: string): any {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'product-accueil/' + slug + '/' + etat, httpOptions);
  }
}
