import { DefaultService } from './default.service';
import { DeviseModel } from './../../../../models/devise.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviseService {


  constructor(
    private http: HttpClient,
    private defaultSvc: DefaultService
  ) { }

  getDevises(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'devise', httpOptions);
  }

  saveDevise(devise: DeviseModel): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'devise', devise, httpOptions);
  }
}
