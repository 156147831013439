import { DefaultService } from './default.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LesServicesService {

  constructor(
    private defaultSvc: DefaultService,
    private http: HttpClient
  ) { }

  save(service) {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'service', service, httpOptions);
  }

  getAll() {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'service', httpOptions);
  }

  updateService(service) {
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.post(this.defaultSvc.urlWeb + 'update_service', service, httpOptions);
  }

  getServiceBySlug(slug:string){
    const httpOptions = {
      headers : new HttpHeaders({
        'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    }
    return this.http.get(this.defaultSvc.urlWeb + 'get_service_by_slug/' + slug, httpOptions);
  }

}
