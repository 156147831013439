import { DefaultService } from './default.service';
import { StockModel } from './../../../../models/stock.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(
    private defaultSvc: DefaultService,
    private http: HttpClient
  ) { }

  saveProduct(stock: StockModel): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'stock', stock, httpOptions);
  }

  getStocks(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'stock', httpOptions);
  }

  getStockByslug(slug: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'stock_by_slug/' + slug, httpOptions);
  }

  getStockOnlyByslug(slug: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'stock_only_by_slug/' + slug, httpOptions);
  }

  addProductToStock(data: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.post(this.defaultSvc.urlWeb + 'produit-stock', data, httpOptions);
  }

  getApprovisionnements(): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(this.defaultSvc.urlWeb + 'appro', httpOptions);
  }

  paginate(url: string): any {
    const httpOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('web_token')}`
      })
    };
    return this.http.get(url, httpOptions);
  }

}
